import { darken } from 'polished';
import { css } from 'styled-components';

export default css`
  // # https://chakra-ui.com/docs/components/overlay/menu
  .chakra-menu__menu-list {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => darken(0.1, theme.colors.white)};

    .chakra-menu__menuitem:active,
    .chakra-menu__menuitem:hover,
    .chakra-menu__menuitem:focus,
    .chakra-menu__menuitem {
      background-color: ${({ theme }) => theme.colors.white};
      border-width: 0;

      color: ${({ theme }) => theme.colors.extraDark.color};
      padding: 1rem;

      &:hover {
        background-color: ${({ theme }) => darken(0.1, theme.colors.white)};
      }
    }
  }
`;
