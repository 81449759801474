import { Container } from './styles';

interface IAppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper: React.FC<IAppWrapperProps> = ({ children }) => (
  <Container>
    <div>{children}</div>
  </Container>
);
export default AppWrapper;
