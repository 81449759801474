import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.light.color};

  & > div {
    height: 100%;
    max-width: 800px;
    margin: 0 auto;

    background-color: ${({ theme }) => theme.colors.white};
  }
`;
